@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@layer base {

  html,
  body {
    overflow-x: hidden;
    @apply antialiased;
  }

  body {
    font-size: var(--fontSize-mediumText);
    font-weight: var(--fontWeight-mediumText);
    line-height: var(--lineHeight-mediumText);
    letter-spacing: var(--letterSpacing-mediumText);
    font-family: var(--fontFamily-text);
  }

  ul {
    @apply list-disc pl-4;
  }

  ol {
    @apply list-decimal pl-4;
  }

  li {
    @apply my-1;
  }

  #root,
  #__next {
    isolation: isolate;
    height: 100%;
  }

  html {
    --emphasize-strong: 600;
    --emphasize-weak: 500;
    --emphasize-none: 400;
  }
}

@layer utilities {
  .outer-focus {
    @apply ring-2 ring-primary5 ring-offset-2 outline-none;
  }

  .inner-focus {
    @apply border-primary5 outline-none;
  }

  .no-focus {
    @apply outline-none ring-0;
  }
}

@layer utilities {
  .colorScheme-gray {
    --colors-colorScheme1: var(--colors-gray1);
    --colors-colorScheme2: var(--colors-gray2);
    --colors-colorScheme3: var(--colors-gray3);
    --colors-colorScheme4: var(--colors-gray4);
    --colors-colorScheme5: var(--colors-gray5);
    --colors-colorScheme6: var(--colors-gray6);
    --colors-colorScheme7: var(--colors-gray7);
    --colors-colorScheme8: var(--colors-gray8);
    --colors-colorScheme9: var(--colors-gray9);
    --colors-colorScheme10: var(--colors-gray10);
  }

  .colorScheme-primary {
    --colors-colorScheme1: var(--colors-primary1);
    --colors-colorScheme2: var(--colors-primary2);
    --colors-colorScheme3: var(--colors-primary3);
    --colors-colorScheme4: var(--colors-primary4);
    --colors-colorScheme5: var(--colors-primary5);
    --colors-colorScheme6: var(--colors-primary6);
    --colors-colorScheme7: var(--colors-primary7);
    --colors-colorScheme8: var(--colors-primary8);
    --colors-colorScheme9: var(--colors-primary9);
    --colors-colorScheme10: var(--colors-primary10);
  }

  .colorScheme-secondary {
    --colors-colorScheme1: var(--colors-secondary1);
    --colors-colorScheme2: var(--colors-secondary2);
    --colors-colorScheme3: var(--colors-secondary3);
    --colors-colorScheme4: var(--colors-secondary4);
    --colors-colorScheme5: var(--colors-secondary5);
    --colors-colorScheme6: var(--colors-secondary6);
    --colors-colorScheme7: var(--colors-secondary7);
    --colors-colorScheme8: var(--colors-secondary8);
    --colors-colorScheme9: var(--colors-secondary9);
    --colors-colorScheme10: var(--colors-secondary10);
  }

  .colorScheme-tertiary {
    --colors-colorScheme1: var(--colors-tertiary1);
    --colors-colorScheme2: var(--colors-tertiary2);
    --colors-colorScheme3: var(--colors-tertiary3);
    --colors-colorScheme4: var(--colors-tertiary4);
    --colors-colorScheme5: var(--colors-tertiary5);
    --colors-colorScheme6: var(--colors-tertiary6);
    --colors-colorScheme7: var(--colors-tertiary7);
    --colors-colorScheme8: var(--colors-tertiary8);
    --colors-colorScheme9: var(--colors-tertiary9);
    --colors-colorScheme10: var(--colors-tertiary10);
  }

  .colorScheme-danger {
    --colors-colorScheme1: var(--colors-danger1);
    --colors-colorScheme2: var(--colors-danger2);
    --colors-colorScheme3: var(--colors-danger3);
    --colors-colorScheme4: var(--colors-danger4);
    --colors-colorScheme5: var(--colors-danger5);
    --colors-colorScheme6: var(--colors-danger6);
    --colors-colorScheme7: var(--colors-danger7);
    --colors-colorScheme8: var(--colors-danger8);
    --colors-colorScheme9: var(--colors-danger9);
    --colors-colorScheme10: var(--colors-danger10);
  }

  .colorScheme-success {
    --colors-colorScheme1: var(--colors-success1);
    --colors-colorScheme2: var(--colors-success2);
    --colors-colorScheme3: var(--colors-success3);
    --colors-colorScheme4: var(--colors-success4);
    --colors-colorScheme5: var(--colors-success5);
    --colors-colorScheme6: var(--colors-success6);
    --colors-colorScheme7: var(--colors-success7);
    --colors-colorScheme8: var(--colors-success8);
    --colors-colorScheme9: var(--colors-success9);
    --colors-colorScheme10: var(--colors-success10);
  }

  .colorScheme-warning {
    --colors-colorScheme1: var(--colors-warning1);
    --colors-colorScheme2: var(--colors-warning2);
    --colors-colorScheme3: var(--colors-warning3);
    --colors-colorScheme4: var(--colors-warning4);
    --colors-colorScheme5: var(--colors-warning5);
    --colors-colorScheme6: var(--colors-warning6);
    --colors-colorScheme7: var(--colors-warning7);
    --colors-colorScheme8: var(--colors-warning8);
    --colors-colorScheme9: var(--colors-warning9);
    --colors-colorScheme10: var(--colors-warning10);
  }

  .colorScheme-info {
    --colors-colorScheme1: var(--colors-info1);
    --colors-colorScheme2: var(--colors-info2);
    --colors-colorScheme3: var(--colors-info3);
    --colors-colorScheme4: var(--colors-info4);
    --colors-colorScheme5: var(--colors-info5);
    --colors-colorScheme6: var(--colors-info6);
    --colors-colorScheme7: var(--colors-info7);
    --colors-colorScheme8: var(--colors-info8);
    --colors-colorScheme9: var(--colors-info9);
    --colors-colorScheme10: var(--colors-info10);
  }

  .colorScheme-inherit {
    --colors-colorScheme1: inherit;
    --colors-colorScheme2: inherit;
    --colors-colorScheme3: inherit;
    --colors-colorScheme4: inherit;
    --colors-colorScheme5: inherit;
    --colors-colorScheme6: inherit;
    --colors-colorScheme7: inherit;
    --colors-colorScheme8: inherit;
    --colors-colorScheme9: inherit;
    --colors-colorScheme10: inherit;
  }
}